const tokyoWards = [
  { isChecked: false, name: "Adachi" },
  { isChecked: false, name: "Arakawa" },
  { isChecked: false, name: "Bunkyo" },
  { isChecked: false, name: "Chiyoda" },
  { isChecked: false, name: "Chuo" },
  { isChecked: false, name: "Edogawa" },
  { isChecked: false, name: "Itabashi" },
  { isChecked: false, name: "Katsushika" },
  { isChecked: false, name: "Kita" },
  { isChecked: false, name: "Koto" },
  { isChecked: false, name: "Meguro" },
  { isChecked: false, name: "Minato" },
  { isChecked: false, name: "Nakano" },
  { isChecked: false, name: "Nerima" },
  { isChecked: false, name: "Ota" },
  { isChecked: false, name: "Setagaya" },
  { isChecked: false, name: "Shibuya" },
  { isChecked: false, name: "Shinagawa" },
  { isChecked: false, name: "Shinjuku" },
  { isChecked: false, name: "Suginami" },
  { isChecked: false, name: "Sumida" },
  { isChecked: false, name: "Taito" },
  { isChecked: false, name: "Toshima" },
  { isChecked: false, name: "Other" },
];

export default tokyoWards;
